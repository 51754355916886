import React from 'react';
import {
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
  IconButton,
  ButtonSize,
} from 'wix-ui-tpa/cssVars';
import Error from 'wix-ui-icons-common/on-stage/Error';
import Close from 'wix-ui-icons-common/on-stage/Close';
import { classes, st } from './form-error-notification.st.css';
import { useCommonsTranslate } from '~commons/hooks/use-commons-translate';

export const FormErrorNotification: React.FC<{
  text: string;
  cta?: React.ReactNode;
  className?: string;
  onClose?: () => void;
  showIcon?: boolean;
  dataHook?: string;
}> = ({ text, cta, className, onClose, dataHook, showIcon = true }) => {
  const t = useCommonsTranslate();
  return (
    <SectionNotification
      type={NOTIFICATION_TYPE.error}
      className={st(classes.root, className)}
      data-hook={dataHook}
    >
      {showIcon && <SectionNotification.Icon icon={<Error aria-hidden="true" />} />}
      <SectionNotification.Text className={classes.text}>{text}</SectionNotification.Text>
      {cta}
      {onClose && (
        <IconButton
          aria-label={t('close-button.aria-label')}
          size={ButtonSize.tiny}
          icon={<Close />}
          onClick={onClose}
        />
      )}
    </SectionNotification>
  );
};
