import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ProductPreview } from '../product-preview/product-preview';
import { CollectionForm } from '../collection-form/collection-form';
import styles from './app.scss';
import { useApi } from '../api-provider/use-api';
import { Loader } from '../loader/loader';
import { unreachable } from '~/ts-utils';
import { FallbackState } from '../fallback-state/fallback-state';
import PreviewBlocker from '~commons/settings/components/components/preview-blocker/preview-blocker';

export const App: React.FC = () => {
  const { appState, baseParams } = useApi((state) => ({
    appState: state.appState,
    baseParams: state.baseParams,
  }));
  const { isPreview, isEditor } = baseParams;
  const { isMobile } = useEnvironment();
  return (
    <div className={styles.root}>
      {(() => {
        switch (appState.type) {
          case 'LOADING':
            return <Loader />;
          case 'READY':
            const { entity } = appState;
            return (
              <>
                {(isPreview || isEditor) && <PreviewBlocker isMobile={isMobile} />}
                {entity && (
                  <ProductPreview
                    product={{
                      imageUrl: entity.imageUrl,
                      name: entity.name,
                    }}
                  />
                )}
                <CollectionForm appState={appState} />
              </>
            );
          case 'ERROR':
            return <FallbackState />;
          default:
            throw unreachable(appState);
        }
      })()}
    </div>
  );
};
